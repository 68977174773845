<template>
  <div id="msgAll">
    <div class="classify">
      <div class="left">
        <span class="status">状态：</span>
        <span
          @click="setStatusAct(0, null)"
          :class="statusAct === 0 ? 'act' : 'statusList'"
          >全部</span
        >
        <!--        <span-->
        <!--          @click="setStatusAct(1, '已审核')"-->
        <!--          :class="statusAct === 1 ? 'act' : 'statusList'"-->
        <!--        >已审核</span-->
        <!--        >-->
        <span
          @click="setStatusAct(1, 1)"
          :class="statusAct === 1 ? 'act' : 'statusList'"
          >已发布</span
        >
        <span
          @click="setStatusAct(2, 2)"
          :class="statusAct === 2 ? 'act' : 'statusList'"
          >审核中</span
        >
        <span
          @click="setStatusAct(3, 3)"
          :class="statusAct === 3 ? 'act' : 'statusList'"
          >未通过</span
        >
        <span
          @click="setStatusAct(4, 4)"
          :class="statusAct === 4 ? 'act' : 'statusList'"
          >已下架</span
        >
        <span
          @click="setStatusAct(5, 5)"
          :class="statusAct === 5 ? 'act' : 'statusList'"
          >草稿</span
        >
      </div>
      <div class="right">
        <span class="time">时间：</span>
        <DatePicker
          :value="Timevalue"
          format="yyyy/MM/dd"
          @on-change="handleChange"
          type="daterange"
          split-panels
          placement="bottom-end"
          placeholder="Select date"
          style="width: 220px"
        ></DatePicker>
        <span class="time">标题：</span
        ><Input
          v-model="search"
          placeholder="请输入标题进行搜索"
          class="searchinput"
        >
          <Icon @click="handleClick" type="ios-search" slot="suffix" />
        </Input>
        <input type="text" class="elHidden" />
      </div>
    </div>
    <div class="box">
      <Spin size="large" fix v-show="spinShow"></Spin>
      <div class="list" v-for="item in list" :key="item.articleId">
        <div style="display: flex;width: 1014px">
          <img
            class="listImg"
            v-if="item.cover"
            v-lazy="`${$global.imgUrl}${item.cover}`"
            alt=""
          />
          <div class="left">
            <div class="msg">
              <div class="title">
                {{ item.title }}
                <br />
                <span>{{ item.createTime }}</span>
              </div>
              <div
                v-if="item.status !== 2"
                :style="item.auditStatus | colored"
                class="type"
              >
                {{ item.auditStatus | type }}
                <span v-if="item.auditStatus === 3">{{
                  "原因：" + item.opinion
                }}</span>
                <span v-if="item.auditStatus === 2">
                  <span class="key"
                    >阅读量：<span class="value">{{ item.readPv }}</span></span
                  ><span class="key"
                    >收藏量：<span class="value">{{ item.collectPv }}</span></span
                  ><span class="key"
                    >分享量：<span class="value">{{ item.sharePv }}</span></span
                  ></span
                >
              </div>
              <div v-else class="type" style="color: #f32048">已下架</div>
            </div>
          </div>
        </div>
        <div class="edit" v-if="item.status !== 2">
          <template v-if="item.auditStatus === 2">
            <div class="right">
              <div class="none" style="color: #ddd; border: 1px solid #ddd">
                修改
              </div>
              <div class="del" @click="closeFile(item)">下架</div>
            </div>
          </template>
          <template v-if="item.auditStatus === 0">
            <div class="right">
              <div class="edti" style="color: #ddd; border: 1px solid #ddd">
                修改
              </div>
              <div class="del" style="color: #ddd; border: 1px solid #ddd">
                删除
              </div>
            </div>
          </template>
          <template v-if="item.auditStatus === 3">
            <div class="right">
              <div class="edti" @click="edit(item)">修改</div>
              <div class="del" @click="del(item)">删除</div>
            </div>
          </template>
          <template v-if="item.auditStatus === 1">
            <div class="right">
              <div class="edti" @click="edit(item)">修改</div>
              <div class="del" @click="del(item)">删除</div>
            </div>
          </template>
        </div>
        <div class="edit" v-else>
          <div class="right">
            <div class="none">修改</div>
            <div class="del" @click="del(item)">删除</div>
          </div>
        </div>
      </div>
      <div class="no-results" v-show="total === 0 && !spinShow">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无作品</p>
      </div>
    </div>
    <Paging
      :total="total"
      :curPage="page"
      :limit="7"
      :showPage="5"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import Paging from "@/components/paging/index.vue";
import { getUserMessageMrgList, delId } from "../../../../../apis/user";

export default {
  name: "MsgAll",
  components: {
    Paging,
  },
  data() {
    return {
      statusAct: 0,
      Timevalue: [null, null],
      search: "",
      page: 1,
      total: 0,
      status: null,
      list: [],
      spinShow: false,
    };
  },
  filters: {
    colored(num) {
      if (num === 2) {
        return "color:#999;";
      } else if (num === 0) {
        return "color:#FD8230;";
      } else if (num === 3) {
        return "color:#F32048;";
      } else if (num === 1) {
        return "color:#00A4FF;";
      }
    },
    type: function (num) {
      if (num === 2) {
        return "已发布";
      } else if (num === 0) {
        return "审核中";
      } else if (num === 3) {
        return "未通过";
      } else if (num === 1) {
        return "草稿";
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async closeFile(item) {
      const resp = await this.$apis.userServe.closeFile({
        articleId: item.articleId,
        articleType: 5,
      });
      if (resp.resultCode == "200") {
        this.$Message.success(resp.resultDesc);
        this.getList();
      } else {
        this.$Message.error(resp.resultDesc);
      }
    },
    edit(e) {
      let { articleId } = e;
      this.$router.push(`/informationForm?id=${articleId}&title=资讯`);
    },
    async del(e) {
      const obj = {
        articleId: e.articleId,
        articleType: 5,
        auditStatus: e.auditStatus,
      };
      console.log(obj);
      const resp = await delId(obj);
      if (resp.resultCode == "200") {
        this.$Message.success(resp.resultDesc);
        this.getList();
      } else {
        this.$Message.error(resp.resultDesc);
      }
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 7,
        title: this.search,
        beginTime: this.Timevalue[0],
        endTime: this.Timevalue[1],
        status: this.status,
      };
      this.spinShow = true;
      const { data } = await getUserMessageMrgList(obj);
      let { total } = data;
      this.total = Number(total);
      this.list = data.list;
      this.spinShow = false;
    },
    handlePager(e) {
      console.log(e);

      this.page = e;
      this.getList();
    },
    handleClick() {
      this.page = 1;
      this.getList();
    },
    handleChange(value) {
      console.log(value);
      this.page = 1;
      this.getList();
    },
    setStatusAct(i, status) {
      this.statusAct = i;
      this.status = status;
      this.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
#msgAll {
  margin-left: 40px;
  margin-right: 40px;

  .classify {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;

    .searchinput {
      width: 260px;
      border-radius: 10px;
    }
    .elHidden {
      width: 0;
      opacity: 0;
    }

    .right {
      height: 30px;

      .time {
        //background: yellow;
        line-height: 30px;
        margin-right: 5px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .left {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      cursor: pointer;

      .status {
        margin-right: 3px;
      }

      .statusList {
        margin-left: 10px;
        margin-right: 10px;
      }

      .act {
        margin-left: 10px;
        margin-right: 10px;
        color: #00a4ff;
      }
    }
  }
  .box {
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
    .list {
      display: flex;
      padding-top: 30px;
      align-items: center;
      padding-bottom: 30px;
      //flex-wrap: wrap;
      //background: pink;
      border-top: 1px dashed #d6d6d6;
      border-bottom: 1px dashed #d6d6d6;
      justify-content: space-between;
      &:hover {
        color: #00a4ff;
      }
      .listImg {
        width: 200px;
        height: 135px;
      }
      .left {
        // display: flex;
        // flex-wrap: wrap;
        // align-content: flex-start;

        .msg {
          margin-left: 14px;
          // display: flex;
          // flex-wrap: wrap;
          // align-items: center;
          // align-content: space-between;

          .title {
            cursor: pointer;
            width: 100%;
            font-size: 20px;
            font-family: Source Han Sans SC;
            font-weight: 400;

            span {
              height: 12px;
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #999999;
            }

            //background: pink;
          }

          .type {
            width: 100%;
            margin-top: 50px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #00a4ff;

            .key {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-right: 25px;
            }
          }
        }

      }

      .right {
        margin-top: 20px;
        display: flex;

        .edti {
          cursor: pointer;
          width: 82px;
          color: #00a4ff;
          height: 34px;
          border: 1px solid #00a4ff;
          border-radius: 10px;
          text-align: center;
          line-height: 34px;
        }

        .none {
          margin-left: 30px;
          cursor: pointer;
          color: #999999;
          width: 82px;
          height: 34px;
          border: 1px solid #999999;
          border-radius: 10px;
          //margin-left: 30px;
          text-align: center;
          line-height: 34px;
        }

        .del {
          margin-left: 30px;
          cursor: pointer;
          color: #f32048;
          //margin-right: 20px;
          text-align: center;
          line-height: 34px;
          width: 82px;
          margin-right: 60px;
          height: 34px;
          border: 1px solid #f32048;
          border-radius: 10px;
        }
      }
    }
    .no-results {
      margin: 0 auto;
      width: 500px;
      // height: 300px;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
